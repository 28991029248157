import { logWarn } from '@klarna-web-sdk/utils/src/logger'

import { MessagePreference } from '../../types'

export const getMessagePreference = (dataset: DOMStringMap): MessagePreference => {
  const attribute = dataset.messagePreference || 'klarna'
  if (!(attribute === 'klarna' || attribute === 'in-store')) {
    logWarn('data-message-preference can only be one of [klarna, in-store]')
    return 'klarna'
  }

  return attribute
}
