import { object, string, undefined as zUndefined, unknown } from 'zod'

export const MessageSchema = object({
  messageId: string(),
  method: string(),
  data: unknown(),
})

export const MessageResolutionSchema = object({
  messageId: string(),
  method: string(),
  origin: string(),
  reject: unknown().or(zUndefined()),
  resolve: unknown().or(zUndefined()),
})
