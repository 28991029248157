import { closeInteractionMode } from '@klarna/flow-interaction-mode'
import { MessengerTarget } from '@klarna-web-sdk/messenger/messenger'
import { MethodHandler, MethodHandlerProps } from '@klarna-web-sdk/messenger/types'

import { MethodsSchema } from './schema'
import { MethodsKey } from './types'

/**
 * SdkBridgeReceiver gets initiated inside Web SDK and receives messages
 * from the outside browser context. It is responsible for validating
 * the incoming messages and creating appropiate resolution.
 */
export class SdkBridgeReceiver {
  private messengerTarget: MessengerTarget
  public registerHandler: MessengerTarget['registerHandler']

  constructor() {
    this.messengerTarget = new MessengerTarget({
      validateOrigin: true,
      removeListenerAfterHandshake: false,
    })
  }

  private async pendingConfirmation(props: MethodHandlerProps) {
    const { data } = props
    const _data = MethodsSchema.pendingConfirmation.data.parse(data)
    return true
  }

  private async closeInteractionMode(): Promise<void> {
    closeInteractionMode()
  }

  public registerAllHandlers() {
    const methodHandlersMap: Record<MethodsKey, MethodHandler> = {
      pendingConfirmation: this.pendingConfirmation.bind(this),
      closeInteractionMode: this.closeInteractionMode.bind(this),
    }

    for (const [method, handler] of Object.entries(methodHandlersMap)) {
      this.messengerTarget.registerHandler(method, handler)
    }
  }
}
