import { backendBridge } from '@klarna-web-sdk/backend-bridge'
import { getUserLocale } from '@klarna-web-sdk/utils/src/getUserLocale'
import { getRegion } from '@klarna-web-sdk/utils/src/region'

import { EffectiveUxModes, ErrorTypes, TrackingEvents } from '../constants'
import {
  PaymentRequest as PaymentRequestType,
  PaymentRequestStateContext as PaymentRequestStateContextType,
} from '../types'
import { camelCaseToSnakeCaseMap, snakeCaseToCamelCaseMap } from './caseConverter'
import { PaymentError } from './paymentError'
import { tracker } from './paymentTracker'
import { EPaymentStorageKeys, paymentStorage } from './storage'
import { store } from './store'

export async function makePaymentRequest(
  paymentRequest: PaymentRequestType,
  effectiveUxMode?: EffectiveUxModes
) {
  const paymentRequestId = store.get('paymentRequestId')

  tracker().event(TrackingEvents.MAKE_PAYMENT_REQUEST_CALLED, { effectiveUxMode, paymentRequestId })

  if (effectiveUxMode === EffectiveUxModes.REDIRECT && !paymentRequest?.config?.redirectUrl) {
    throw new PaymentError(ErrorTypes.INPUT_ERROR, 'PaymentRequest.config.redirectUrl is required')
  }

  const apiDataMap = {
    ...camelCaseToSnakeCaseMap(paymentRequest),
    internal: {
      effective_ux_mode: effectiveUxMode,
    },
  }

  const currency = paymentRequest?.currency || store.get('paymentRequest').currency

  const apiResponse = await backendBridge.call({
    method: 'paymentApiSendAuthorizationRequest',
    data: {
      body: apiDataMap,
      region: getRegion({ paymentRequestId, currency, locale: getUserLocale() }),
    },
  })

  const response = snakeCaseToCamelCaseMap(apiResponse)

  store.set('paymentRequest', response.paymentRequest)
  store.set('paymentRequestId', response.paymentRequestId)
  store.set('paymentRequestState', response.state)
  store.set('paymentRequestStateContext', response.stateContext as PaymentRequestStateContextType)
  paymentStorage.set(EPaymentStorageKeys.PaymentRequestId, response.paymentRequestId)

  tracker().event(TrackingEvents.MAKE_PAYMENT_REQUEST_COMPLETED, {
    paymentRequestId: response.paymentRequestId,
  })

  return response
}
