import { ButtonLabel as EButtonLabel, klarnaButtons } from '../constants'
import { ETranslationKey } from '../i18n'

export const getButtonLabel = (label: EButtonLabel, type: klarnaButtons): ETranslationKey => {
  if (type === klarnaButtons.IDENTITY) {
    // TODO: update to ConnectWith after KPC-1109 when copy is ready
    if (label === EButtonLabel.CONNECT) return ETranslationKey.ContinueWith
    // TODO: update to SigninWith after KPC-1109 when copy is ready
    if (label === EButtonLabel.SIGNIN) return ETranslationKey.ContinueWith
    // TODO: update to SignupWith after KPC-1109 when copy is ready
    if (label === EButtonLabel.SIGNUP) return ETranslationKey.ContinueWith

    return ETranslationKey.ContinueWith
  } else if (type === klarnaButtons.PAYMENT) {
    if (label === EButtonLabel.CONTINUE) return ETranslationKey.ContinueWith
    // TODO: update to CheckoutWith after KPC-1109 when copy is ready
    if (label === EButtonLabel.CHECKOUT) return ETranslationKey.PayWith
    // TODO: update to SubscribeWith after KPC-1109 when copy is ready
    if (label === EButtonLabel.SUBSCRIBE) return ETranslationKey.ContinueWith
    // TODO: update to DonateWith after KPC-1109 when copy is ready
    if (label === EButtonLabel.DONATE) return ETranslationKey.ContinueWith

    return ETranslationKey.PayWith
  }

  return ETranslationKey.ContinueWith
}

export { EButtonLabel }
