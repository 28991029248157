import { EventBus } from '@klarna-web-sdk/utils'
import { OAuth2Error } from 'oauth4webapi'

import { IdentitySignInResponse } from '../utils/getSigninResponse'

type IdentityEventName = 'ready' | 'signin' | 'error'
type IdentityEventData = IdentitySignInResponse | OAuth2Error | Error | undefined

class IdentityEvents extends EventBus<IdentityEventName, IdentityEventData> {
  // eslint-disable-next-line no-use-before-define
  private static instance: IdentityEvents

  private constructor() {
    super()
  }

  static getInstance(): IdentityEvents {
    if (!IdentityEvents.instance) {
      IdentityEvents.instance = new IdentityEvents()
    }

    return IdentityEvents.instance
  }
}

export default IdentityEvents
