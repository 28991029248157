import type { CountryCode, Region } from '../types.d.ts'

export type RegionMapping = {
  [key in Lowercase<CountryCode>]: Lowercase<Region>
}

export const regionMapping: RegionMapping = {
  at: 'eu',
  au: 'oc',
  be: 'eu',
  ca: 'na',
  ch: 'eu',
  cz: 'eu',
  de: 'eu',
  dk: 'eu',
  es: 'eu',
  fi: 'eu',
  fr: 'eu',
  gb: 'eu',
  gr: 'eu',
  ie: 'eu',
  it: 'eu',
  mx: 'na',
  nl: 'eu',
  no: 'eu',
  nz: 'oc',
  pl: 'eu',
  pt: 'eu',
  ro: 'eu',
  se: 'eu',
  us: 'na',
}
