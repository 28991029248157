import type { EventBus } from '@klarna-web-sdk/utils'
import type { SDKConfig } from '@klarna-web-sdk/utils/src/types'

import { ErrorTypes, PaymentRequestState } from '../constants'
import { PaymentRequestAllOptional, PaymentRequestOptions } from '../schema'
import type {
  PaymentRequest as PaymentRequestType,
  PaymentRequestOptions as PaymentRequestOptionsType,
  PaymentRequestStateContext as PaymentRequestStateContextType,
} from '../types'
import { PaymentError } from './paymentError'

interface IStoreData {
  config?: SDKConfig
  emit?: typeof EventBus.prototype.emit
  paymentRequest?: Partial<PaymentRequestType>
  paymentRequestId?: string
  paymentRequestOptions?: Partial<PaymentRequestOptionsType>
  paymentRequestState: PaymentRequestState
  paymentRequestPreviousState?: PaymentRequestState
  paymentRequestResult?: {
    paymentConfirmationToken?: string | null
  }
  paymentRequestStateContext?: Partial<PaymentRequestStateContextType>
}

type StoreDataKey = keyof IStoreData

interface IStore {
  clear: () => void
  delete: (key: StoreDataKey) => void
  get: <T extends StoreDataKey>(key: T) => IStoreData[T]
  set: <T extends StoreDataKey>(key: T, value: IStoreData[T]) => void
  updatePaymentRequest: (context: Partial<PaymentRequestType>) => void
  updatePaymentRequestOptions: (options: Partial<PaymentRequestOptionsType>) => void
  resetPaymentRequest: () => void
}

export const StoreData: IStoreData = {
  paymentRequest: {},
  paymentRequestOptions: {},
  paymentRequestState: PaymentRequestState.CREATED,
}

export const store: IStore = {
  clear() {
    Object.keys(StoreData).forEach((key) => {
      delete StoreData[key as StoreDataKey]
    })
  },
  set(key, value) {
    StoreData[key] = value
  },
  get(key) {
    return StoreData[key]
  },
  delete(key) {
    delete StoreData[key]
  },
  updatePaymentRequest(context) {
    StoreData.paymentRequest = {
      ...StoreData.paymentRequest,
      ...context,
    }
  },
  updatePaymentRequestOptions(options) {
    StoreData.paymentRequestOptions = {
      ...StoreData.paymentRequestOptions,
      ...options,
    }
  },
  resetPaymentRequest() {
    StoreData.paymentRequest = {}
    StoreData.paymentRequestId = undefined
    StoreData.paymentRequestOptions = {}
    StoreData.paymentRequestPreviousState = undefined
    StoreData.paymentRequestResult = undefined
    StoreData.paymentRequestState = PaymentRequestState.CREATED
    StoreData.paymentRequestStateContext = undefined
  },
}

export const storeUpdatePaymentRequest = (paymentRequest: Partial<PaymentRequestType>) => {
  const parsedPaymentRequest = PaymentRequestAllOptional.safeParse(paymentRequest)

  if (parsedPaymentRequest.success === false) {
    throw new PaymentError(
      ErrorTypes.INPUT_ERROR,
      'Invalid PaymentRequest',
      parsedPaymentRequest.error
    )
  }

  store.updatePaymentRequest(parsedPaymentRequest.data)
}

export const storeUpdatePaymentRequestOptions = (options: Partial<PaymentRequestOptionsType>) => {
  const parsedOptions = PaymentRequestOptions.safeParse(options)

  if (parsedOptions.success === false) {
    throw new PaymentError(
      ErrorTypes.INPUT_ERROR,
      'Invalid PaymentRequestOptions',
      parsedOptions.error
    )
  }

  store.updatePaymentRequestOptions(parsedOptions.data)
}
