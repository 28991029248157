import { html } from 'lit'

const kLogo = html`<svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.8039 0.304688H9.94258C9.94258 3.46943 8.48749 6.37417 5.95055 8.27456L4.42074 9.42023L10.3482 17.5029H15.2217L9.7677 10.0657C12.353 7.49125 13.8039 4.02786 13.8039 0.304688ZM17.839 13.3736C19.0325 13.3736 20 14.3411 20 15.5346C20 16.7281 19.0325 17.6957 17.839 17.6957C16.6455 17.6957 15.678 16.7281 15.678 15.5346C15.678 14.3411 16.6455 13.3736 17.839 13.3736ZM3.94903 0.304688V17.5029H0V0.304688H3.94903Z"
  />
</svg>`

export default kLogo
