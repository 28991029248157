import { boolean, object, string } from 'zod'

export const MethodsSchema = {
  pendingConfirmation: {
    data: object({
      payment_confirmation_token: string(),
    }),
    response: boolean(),
  },
  closeInteractionMode: {
    response: boolean(),
  },
}
