import { isLegacyOSMClientId } from '@klarna-web-sdk/utils'
import { API_BASE_URLS } from '@klarna-web-sdk/utils/src/constants'
import { getRegionFromLocale } from '@klarna-web-sdk/utils/src/region'
import { reportSentryError } from '@klarna-web-sdk/utils/src/sentry'

import defaultFallbackContent from '../../checkout/fallback/en-GB'
import {
  CHANNEL,
  HTML_ENDPOINT,
  INLINE_ENDPOINT,
  MERCHANTS_WITH_CUSTOM_ADS,
  MESSAGING_ENDPOINT,
} from '../../config'
import { MessagingArgs } from '../../types'

export type ContentType = 'json' | 'html'

interface QueryParams extends MessagingArgs {
  environment: 'production' | 'playground' | 'staging' | 'development'
  clientId: string
  locale: string
}

interface MemoryCache {
  [url: string]: Response
}

const memoryCache: MemoryCache = {}

export default async function fetchContent(
  {
    locale,
    key,
    paymentAmount,
    clientId,
    accountId,
    theme,
    inline,
    messagePrefix,
    customPaymentMethodIds,
    environment,
    messagePreference,
  }: QueryParams & { accountId?: string },
  type: ContentType = 'json'
) {
  let endpoint = type === 'json' ? MESSAGING_ENDPOINT : HTML_ENDPOINT
  endpoint = inline ? INLINE_ENDPOINT : endpoint
  const region = getRegionFromLocale(locale)
  const baseUrl = `${API_BASE_URLS[environment]}/${region}/cma`
  const clientIdIsLegacy = isLegacyOSMClientId(clientId)

  if (MERCHANTS_WITH_CUSTOM_ADS.includes(clientId)) {
    endpoint = inline ? INLINE_ENDPOINT : HTML_ENDPOINT
  }

  const version = clientIdIsLegacy ? '/v3' : '/v4'

  let url = `${baseUrl}${version}${endpoint}?placement_key=${key}&locale=${locale}&channel=${CHANNEL}`

  if (theme) {
    url = `${url}&theme=${theme}`
  }

  // In the legacy OSM the clientId is a uuid included in the url
  if (clientIdIsLegacy) {
    url = `${url}&client_id=${clientId}`
  }

  if (
    paymentAmount !== undefined &&
    paymentAmount !== '' &&
    // Ignore amount on top-strip and home-page placements
    !key.includes('top-strip') &&
    !key.includes('home')
  ) {
    url = `${url}&payment_amount=${paymentAmount}`
  }

  if (messagePrefix) {
    url = `${url}&message_prefix=${messagePrefix}`
  }

  if (inline) {
    url = `${url}&inline=${inline}`
  }

  if (customPaymentMethodIds) {
    url = `${url}&custom_payment_method_ids=${customPaymentMethodIds}`
  }

  if (messagePreference) {
    url = `${url}&message_preference=${messagePreference}`
  }

  const headers: {
    'Klarna-Client-Type': string
    Authorization?: string
    'Klarna-Account'?: string
  } = {
    'Klarna-Client-Type': 'klarna-web-sdk',
  }

  if (!clientIdIsLegacy) {
    headers['Authorization'] = `basic ${clientId}`
  }

  if (accountId) {
    headers['Klarna-Account'] = accountId
  }

  let response

  try {
    response = memoryCache[url]

    if (!response) {
      // Fetch the URL and store the response in the cache.
      response = await fetch(url, { headers })
      memoryCache[url] = response && response.status === 200 ? response.clone() : undefined
    }

    if (response && response.status === 200) {
      return await response.clone().json()
    }
    // 204
    else if (response && response.status !== 200 && key === 'checkout') {
      let fallbackRequest = defaultFallbackContent
      try {
        if (locale) {
          const resp = await import(`../../checkout/fallback/${locale}.ts`)
          fallbackRequest = resp.default
        } else {
          reportSentryError(`No locale provided. Fallback to en-GB.`)
        }
      } catch (error) {
        reportSentryError(
          `Failed to load the locale file for ${locale}. Fallback to en-GB. ${error.message}`
        )
      }

      return fallbackRequest
    } else {
      /* 204 and not checkout */
      return undefined
    }
  } catch (e) {
    // In case of error on the checkout placement
    // we want to display the static content
    if (key === 'checkout') {
      let fallbackRequest = defaultFallbackContent
      try {
        if (locale) {
          const resp = await import(`../../checkout/fallback/${locale}.ts`)
          fallbackRequest = resp.default
        } else {
          reportSentryError(`No locale provided. Fallback to en-GB.`)
        }
      } catch (error) {
        reportSentryError(
          `Failed to load the locale file for ${locale}. Fallback to en-GB. ${error.message}`
        )
      }
      return fallbackRequest
    }

    // do not report to Sentry, network errors, CORS / CSP errors or
    // browser extensions blocking requests
    if (e.message === 'Failed to fetch' || e.message === 'Load failed') {
      return undefined
    }

    if (!response || response.status < 400 || response.status >= 600) {
      reportSentryError(e)
    }

    return undefined
  }
}
