import { jwtDecode } from 'jwt-decode'

import type { OAuthTokens } from '../components/AuthorizationServer'

interface BillingAddress {
  city: string | null
  country: string | null
  postal_code: string | null
  region: string | null
  street_address: string | null
  street_address_2: string | null
}

interface UserAccountLinking {
  user_account_linking_refresh_token: string
  user_account_linking_id_token: string
}

interface UserAccountProfile {
  billing_address?: BillingAddress
  country?: string | null
  date_of_birth?: string | null
  email?: string | null
  email_verified?: boolean
  family_name?: string | null
  given_name?: string | null
  phone?: string | null
  national_identification_number?: string | null
  national_identification_number_country?: string | null
  locale?: string | null
  sub: string
}

export type IdentitySignInResponse = {
  user_account_profile: UserAccountProfile
  user_account_linking: UserAccountLinking
}

interface IdentityIdToken extends UserAccountProfile {
  at_hash: string
  aud: string[]
  auth_time: number
  exp: number
  iat: number
  iss: string
  jti: string
  login_session_id: string
  nonce: string
  rat: number
  sid: string
}

export const getSigninResponse = ({
  id_token,
  refresh_token,
}: OAuthTokens): IdentitySignInResponse => {
  try {
    const {
      billing_address,
      country,
      date_of_birth,
      email,
      email_verified,
      family_name,
      given_name,
      phone,
      national_identification_number,
      national_identification_number_country,
      locale,
      sub,
    } = jwtDecode<IdentityIdToken>(id_token)

    return {
      user_account_profile: {
        billing_address,
        country,
        date_of_birth,
        email,
        email_verified,
        family_name,
        given_name,
        phone,
        national_identification_number,
        national_identification_number_country,
        locale,
        sub,
      },
      user_account_linking: {
        user_account_linking_refresh_token: refresh_token,
        user_account_linking_id_token: id_token,
      },
    }
  } catch (error) {
    console.error('Error decoding Identity JWT: ', error.message)
  }
}
