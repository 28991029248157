/**
 * @module Klarna SDK
 * @packageDocumentation
 */

import { backendBridge } from '@klarna-web-sdk/backend-bridge'
import IdentitySDK from '@klarna-web-sdk/identity'
import KlarnaPlacement from '@klarna-web-sdk/messaging'
import setupMessagingAPI from '@klarna-web-sdk/messaging/src/api'
import { KlarnaPayment } from '@klarna-web-sdk/payment'
import {
  configureTracker,
  decodeClientToken,
  EVENTS_SAMPLING_RATE,
  getSampleGroup,
  removeUndefinedAttributes,
  sessionStorageHelper,
  setupSentry,
} from '@klarna-web-sdk/utils'
import { getRolloutVariant } from '@klarna-web-sdk/utils/src/getVersion'
import shouldLoadTestDriveBadge from '@klarna-web-sdk/utils/src/shouldLoadTestDriveBadge/shouldLoadTestDriveBadge'
import { TrackerClients } from '@klarna-web-sdk/utils/src/tracker/trackerClients'
import trackerFactory from '@klarna-web-sdk/utils/src/tracker/trackerFactory'
import { Methods, SDKConfig, Session } from '@klarna-web-sdk/utils/src/types'
import { v4 as uuidv4 } from 'uuid'

import { PublicAPI, PublicAPIOSM } from './types'

/**
 * @hidden
 */
class SDK {
  private config: SDKConfig

  private tracker: ReturnType<typeof trackerFactory>
  private session: Session
  private identitySDK: IdentitySDK
  Payment: KlarnaPayment

  Messaging: ReturnType<typeof setupMessagingAPI> // Add MessagingType

  constructor(config: SDKConfig) {
    if (!config?.instanceId) {
      config.instanceId = uuidv4()
    }

    this.config = config

    const storage = sessionStorageHelper(`${config.instanceId}-config`)
    storage && storage.set('config', this.config)

    /**
     * For KCO which uses PACS backend to create sessions we will initiate the SDK with a clientToken.
     * From the clientToken we can extract a payment session client id and environment which will take precedence over directly provided attributes.
     */
    if (this.config.clientToken) {
      this.configureSDKWithClientToken()
    }

    this.tracker = configureTracker({
      config: {
        version: this.config.version,
        environment: this.config.environment,
        sessionId: this.config.sessionId,
      },
      trackerClient: TrackerClients.websdk,
      extraTrackingData: {
        additionalIdentifier: this.config.additionalIdentifier,
        clientId: this.config.clientId,
        accountId: this.config.accountId,
        instanceId: this.config.instanceId,
      },
    })
    this.initSentryUtils()
    this.trackRollout()
    this.initializeSDKFeatures()
    this.loadTestDriveBadge()

    if (__PACKAGE__ !== 'osm') {
      backendBridge.init(this.config)
    }
  }

  private trackRollout() {
    if (getSampleGroup(EVENTS_SAMPLING_RATE.INIT)) {
      const rolloutVariant = getRolloutVariant()
      this.tracker.event('metric_sdk_init', {
        rolloutVariant,
      })
    }
  }

  private loadTestDriveBadge = () => {
    const { environment, clientId } = this.config

    if (shouldLoadTestDriveBadge(environment || '', clientId)) {
      import(/* webpackChunkName: "klarna-test-drive-badge" */ '../elements/test-drive-badge')
    }
  }

  private configureSDKWithClientToken = () => {
    const { sessionId, clientId, environment } = decodeClientToken(this.config.clientToken)

    this.config.environment = environment
    this.config.clientId = clientId

    this.setSession({
      session_id: sessionId,
      client_token: this.config.clientToken,
    })
  }

  private initSentryUtils = () => {
    const {
      accountId,
      clientId,
      clientToken,
      environment,
      version,
      additionalIdentifier,
      instanceId,
    } = this.config

    const tags = removeUndefinedAttributes({
      accountId,
      clientId,
      clientToken,
      instanceId,
      additionalIdentifier,
    })

    setupSentry({ environment, version, tags })
  }

  getMethodsObject(): Methods {
    return {
      getSession: this.getSession,
      setSession: this.setSession,
    }
  }

  private initializeSDKFeatures = () => {
    try {
      this.Messaging = setupMessagingAPI.call(this, this.config, this.getMethodsObject())
      KlarnaPlacement(this, this.config)
    } catch {
      this.tracker.event('error', { message: 'Failed to initialize Messaging SDK' })
    }

    if (__PACKAGE__ !== 'osm') {
      try {
        this.Payment = new KlarnaPayment(this.config)
      } catch {
        // @TODO: re-add error reporting when public API is ready
        // this.tracker.event('error', { message: 'Failed to initialize Payment SDK' })
      }
    }

    if (__PACKAGE__ !== 'osm') {
      try {
        this.identitySDK = new IdentitySDK({
          sessionId: this.config.sessionId,
          environment: this.config.environment,
          clientId: this.config.clientId,
          identityRuntimeConfig: this.config.runtimeConfig?.identity,
        })
      } catch {
        // @TODO: re-add error reporting when public API is ready
        // this.tracker.event('error', { message: 'Failed to initialize Identity SDK' })
      }
    }
  }

  private getSession = () => this.session as Session

  private setSession = (session: Session) => {
    this.session = session
  }

  // For legacy OSM we only need the messaging package
  // @todo: refactor this solution with ticket: https://klarna.atlassian.net/browse/KPC-965
  public getPublicAPI(): PublicAPI | PublicAPIOSM {
    if (__PACKAGE__ !== 'osm') {
      const Payment = this.Payment?.getPublicAPI()
      const Identity = this.identitySDK?.getIdentityPublicAPI()
      const Messaging = this.Messaging
      class PublicAPI {
        Payment = Payment
        Identity = Identity
        Messaging = Messaging
      }

      return new PublicAPI()
    } else {
      const Messaging = this.Messaging
      class PublicAPI {
        Messaging = Messaging
      }

      return new PublicAPI()
    }
  }
}

export default SDK

export type { PublicAPI as KlarnaJS } from './types'

export type { SDKConfig as KlarnaConfiguration } from '@klarna-web-sdk/utils/src/types'
