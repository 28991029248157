import type { ElementArgs } from '@klarna-web-sdk/utils'
import { CreateElement } from '@klarna-web-sdk/utils'
import type { TypeOf } from 'zod'

import type { PaymentButton } from './apis/button'
import type { cancel } from './apis/cancel'
import type { fetch } from './apis/fetch'
import type { initiate } from './apis/initiate'
import type { prepare } from './apis/prepare'
import type { submit } from './apis/submit'
import type { update } from './apis/update'
import { ErrorTypes, PaymentButtonLabel, PaymentRequestState } from './constants'
import type {
  Address,
  Buyer,
  CanMakePaymentOptionsSchema,
  KlarnaUser,
  LineItem,
  PaymentRequestBodyAPI,
  PaymentRequestOptions,
  Shipping,
} from './schema'

export enum Currency {
  EUR = 'EUR',
}

/**
 * County information.
 */
export enum Country {
  DE = 'DE',
}

/**
 * @hidden
 */
export type PaymentRequestBodyAPI = TypeOf<typeof PaymentRequestBodyAPI>

export type CountryCurrency = {
  [K in Currency]: Country[]
}

/**
 * Address information.
 */
export type Address = TypeOf<typeof Address>

/**
 * Shipping information.
 */
export type Shipping = TypeOf<typeof Shipping>

/**
 * Buyer information.
 */
export type Buyer = {
  address?: Address
  email?: string
  familyName?: string
  givenName?: string
  phone?: string
}

/**
 * Line item information.
 */
export type LineItem = TypeOf<typeof LineItem>

/**
 * Klarna user information.
 */

export type KlarnaUser = {
  klarnaUserId: string
  givenName: string
  familyName: string
  email: string
  phone: string
  phoneVerified: boolean
  emailVerified: boolean
  locale: string
  dateOfBirth: string
  nationalIdentification: {
    number: string
    country: string
  }
  address: Address
  shipping: Shipping
}

/**
 * Payment request
 */
export type PaymentRequest = {
  buyer?: Buyer
  currency: string
  paymentAmount: number
  lineItems?: LineItem[]
  shipping?: Shipping
  config?: {
    redirectUrl?: string
  }
  /**
   * Used for storing the consumer-facing order number. It will be displayed to consumers on the Klarna app and
   * other communications. It will also be included in settlement reports for the purpose of reconciliation.
   * If you are a direct merchant with Klarna, this can be the same as payment_authorization_reference.
   * If you are a PSP, this is the reference your merchant generated for their consumer order.
   */
  merchantReference?: string
  /**
   * Reference to the payment session or equivalent
   * resource created on your side. This will be exposed in payment request webhooks
   * (payments.v2.request.*) for the purpose of correlating your resource with the Klarna Payment Request.
   */
  paymentReference?: string
}

/**
 * Payment request options information
 */
export type PaymentRequestOptions = TypeOf<typeof PaymentRequestOptions>

/**
 * Can make payment options information
 */
export type CanMakePaymentOptions = TypeOf<typeof CanMakePaymentOptionsSchema>

/**
 * Payment request state context information
 */
export type PaymentRequestStateContext = {
  distribution?: {
    url?: string
  }
  paymentConfirmationToken?: string
  paymentAuthorizationId?: string
  userAccountProfile?: KlarnaUser
  userAccountLinking?: {
    userAccountLinkingRefreshToken?: string
    userAccountLinkingIdToken?: string
  }
}

/**
 * Payment request response information
 */
export type PaymentRequestResponse = {
  expiresAt?: string
  paymentRequestId?: string
  paymentRequest: PaymentRequest
  previousState?: PaymentRequestState
  state: PaymentRequestState
  stateContext?: PaymentRequestStateContext
}

/**
 * Payment button configuration
 */
export interface PaymentButtonConfiguration extends ElementArgs {
  id?: string
  theme?: string
  shape?: string
  disabled?: string
  loading?: string
  label?: PaymentButtonLabel
  locale?: string
}

/**
 * Error result
 */
export interface ErrorResult {
  errorId: string
  errorFields?: Array<{ attribute: string; reason: string | string[] }>
  errorMessage: string
  errorType: ErrorTypes
}

/**
 * A Payment Request represents a request for a payment. The Payment Request lifecycle is defined in {@link PaymentRequestState}.
 *
 * A Payment Request is either created explicitly by the integrator using `Klarna.Payment.request()` or implicitly by the SDK when a `<klarna-payment-button>` is rendered.
 *
 * Payment requests can be created with initial data (PurchaseContext/TokenContext, options and paymentRequestId). If a payment request is created without initial data, that data is required to be provided when calling a method on the payment request (initiate, prepare, submit, patch).
 *
 * When using a `<klarna-payment-button>`, the initial payment request is passed in the click handler. In the click handler, you can modify the request and choose whether you want to initiate the request.
 *
 * Example of how to programmatically create and initiate a payment request without the Klarna payment button:
 *
 * ```typescript
 * // Note that nothing is actually persisted server-side until initiate is called.
 * const paymentRequest = Klarna.Payment.request()
 *
 * function myClickHandler() {
 *   // payment request is patched with data provided in method calls
 *   paymentRequest.initiate({
 *     currency: "USD",
 *     paymentAmount: 1000
 *   })
 * }
 *
 * Klarna.on("update", (paymentRequest) => {
 *  // Handle payment request state transitions
 * })
 * ```
 *
 * <br/>
 *
 * ```html
 * <button onclick="myClickHandler()">Place payment</button>
 * ```
 */
export interface Request {
  paymentRequestId?: string
  paymentRequest?: Partial<PaymentRequest>
  result?: {
    paymentConfirmationToken?: string | null
  }
  state?: PaymentRequestState
  stateContext?: Partial<PaymentRequestStateContext>
  cancel: typeof cancel
  fetch: typeof fetch
  initiate: typeof initiate
  prepare: typeof prepare
  submit: typeof submit
  update: typeof update
}

export interface KlarnaPaymentButton extends ReturnType<CreateElement['getPublicAPI']> {
  toggleState: typeof PaymentButton.prototype.toggleState
  on: typeof PaymentButton.prototype.toggleState
}

/**
 * On Update Callback information
 */
export type OnUpdateCallback = (PaymentRequest: Request) => Promise<boolean> | void
export type PaymentEventData = Request | string
