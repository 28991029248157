/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck -- TODO: improve typing for this function

import { CamelCasedPropertiesDeep, SnakeCasedPropertiesDeep } from 'type-fest'

const snakeCaseToCamelCase = (str: string) => {
  return str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase())
}

export const snakeCaseToCamelCaseMap = <T extends Record<string, any>>(
  snakeCaseObj: T
): CamelCasedPropertiesDeep<T> => {
  if (Array.isArray(snakeCaseObj)) {
    return snakeCaseObj.map((item) => snakeCaseToCamelCaseMap(item))
  } else if (snakeCaseObj != null && snakeCaseObj.constructor === Object) {
    return Object.keys(snakeCaseObj).reduce((result, key) => {
      const camelCaseKey = snakeCaseToCamelCase(key)
      result[camelCaseKey] = snakeCaseToCamelCaseMap(snakeCaseObj[key])
      return result
    }, {})
  }
  return snakeCaseObj
}

const camelCaseToSnakeCase = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const camelCaseToSnakeCaseMap = <T extends Record<string, any>>(
  camelCaseObj: T
): SnakeCasedPropertiesDeep<T> => {
  if (Array.isArray(camelCaseObj)) {
    return camelCaseObj.map((item) => camelCaseToSnakeCaseMap(item))
  } else if (camelCaseObj != null && camelCaseObj.constructor === Object) {
    return Object.keys(camelCaseObj).reduce((result, key) => {
      const snakeCaseKey = camelCaseToSnakeCase(key)
      result[snakeCaseKey] = camelCaseToSnakeCaseMap(camelCaseObj[key])
      return result
    }, {})
  }
  return camelCaseObj
}
