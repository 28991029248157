import { Environment, Region } from '@klarna-web-sdk/utils/src/types'
import retry from 'async-retry'
import * as oauth from 'oauth4webapi'

import type { EnvironmentConfig } from '../config'
import { errorHandler } from '../errors'
import { getEnvironmentConfig } from '../utils/environment'
import { RETRY_OPTIONS } from '../utils/retry'
import { AuthorizationServer } from './AuthorizationServer'

const fetchAuthorizationServerMetadata = async (environmentConfig: EnvironmentConfig) => {
  // discoveryRequest
  const discoveryResponse = await retry(
    async () => oauth.discoveryRequest(new URL(environmentConfig.oidc.idpUrl)),
    {
      ...RETRY_OPTIONS,
      onRetry: (error, attempt) => {
        errorHandler(error, { errorTitle: 'Discovery request failed!', attempt })
      },
    }
  )

  // processDiscoveryResponse
  const authorizationServerMetadata = await oauth.processDiscoveryResponse(
    new URL(environmentConfig.oidc.issuerUrl),
    discoveryResponse
  )

  if (authorizationServerMetadata.code_challenge_methods_supported?.includes('S256') !== true) {
    // This example assumes S256 PKCE support is signalled
    // If it isn't supported, random `nonce` must be used for CSRF protection.
    throw new Error('Code challenge method not supported')
  }

  return authorizationServerMetadata
}

export class AuthorizationServerRegistry {
  private static authorizationServer: AuthorizationServer
  // todo: discuss is there an SDK default env?
  private static environment: Environment = 'production'
  // Default to EU region
  private static region: Region = 'EU'

  public static setEnvironment(environment: Environment) {
    AuthorizationServerRegistry.environment = environment
  }

  public static setRegion(region: Region) {
    AuthorizationServerRegistry.region = region
  }

  public static async getInstance() {
    if (!AuthorizationServerRegistry.authorizationServer) {
      const environmentConfig = getEnvironmentConfig(
        AuthorizationServerRegistry.region,
        AuthorizationServerRegistry.environment
      )

      // todo: discuss caching authServerMetadata in sessionStorage for 5 mins to avoid the error below?
      // See: `TypeError: Failed to fetch discoveryResponse(oidc/index)`
      // https://klarna-1.sentry.io/issues/4216190645/events/c8f9c77ff4df4dfaa1a0b8004a4f834d/
      const authServerMetadata = await fetchAuthorizationServerMetadata(environmentConfig)

      AuthorizationServerRegistry.authorizationServer = new AuthorizationServer(
        authServerMetadata,
        environmentConfig
      )
    }

    return AuthorizationServerRegistry.authorizationServer
  }
}
