import { SUPPORTED_LOCALES } from '@klarna-web-sdk/messaging/src/config'

export const isValidLocale = (
  locale: string | null,
  supportedLocales: string[] = SUPPORTED_LOCALES
): boolean => {
  if (!locale) {
    return false
  }
  return supportedLocales.includes(locale.toLowerCase())
}
