import { ApiError } from '@klarna-web-sdk/backend-bridge'
import { getSentryClient, logError } from '@klarna-web-sdk/utils'
import { v4 as uuid } from 'uuid'
import { ZodError } from 'zod'

import { ErrorTypes } from '../constants'
import { ErrorResult } from '../types'
import { tracker } from './paymentTracker'
import { store } from './store'

export class PaymentError implements ErrorResult {
  public errorId: ErrorResult['errorId']
  public errorFields?: ErrorResult['errorFields']
  public errorMessage: ErrorResult['errorMessage']
  public errorType: ErrorResult['errorType']

  constructor(
    type: ErrorTypes,
    message: string,
    originalError?: PaymentError | ApiError | ZodError | Error | unknown
  ) {
    this.errorId = uuid()
    this.errorFields = undefined
    this.errorMessage = message
    this.errorType = type

    if (originalError instanceof ApiError && originalError.response) {
      const response = originalError.response
      if (response.error_id) this.errorId = response.error_id
      if (response.error_type) this.errorType = response.error_type as ErrorTypes
      if (response.error_message) this.errorMessage = response.error_message
    }

    if (originalError instanceof ZodError) {
      this.errorFields = originalError.issues.map((issue) => ({
        attribute: issue.path.join('.'),
        reason: issue.message,
      }))
    }

    if (originalError instanceof PaymentError) {
      this.errorId = originalError.errorId
    }

    /**
     * track error in frontend event router
     */
    tracker().event(this.errorType, {
      error: this.errorId,
      message: this.errorMessage,
      paymentRequestId: store.get('paymentRequestId'),
    })

    /**
     * report error to sentry
     */
    const _reportableError = new Error(this.errorMessage)
    _reportableError.name = this.errorType

    try {
      const sentryClient = getSentryClient()
      sentryClient.setTag('errorID', this.errorId)
      sentryClient.setTag('errorType', this.errorType)
      originalError && sentryClient.setExtras({ originalError })
      sentryClient.report(_reportableError)
    } catch (error) {
      logError(error)
    }

    // TODO: log errors in debug mode - KPC-1239
  }
}
