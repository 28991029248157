import { sessionStorageHelper } from '@klarna-web-sdk/utils'

const IDENTITY_STORAGE_KEY = 'identity-api'

export const identitySessionStorage = sessionStorageHelper(IDENTITY_STORAGE_KEY)

export type IdentitySessionStorage = typeof identitySessionStorage

export const IdentityStorageKeys = {
  CodeVerifier: 'klarna-sign-in-code-verifier',
  Nonce: 'klarna-sign-in-nonce',
  State: 'klarna-sign-in-state',
  SessionId: 'klarna-sign-in-session-id',
  ButtonConfigurations: 'klarna-sign-in-button-configurations',
  AuthorizationServerCache: 'klarna-sign-in-authorization-server-cache',
} as const
