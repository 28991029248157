import retry from 'async-retry'
import * as oauth from 'oauth4webapi'

import type { EnvironmentConfig } from '../config'
import { errorHandler } from '../errors'
import { TokenExchangeError } from '../errors/token-exchange-error'
import { IdentityEvents } from '../identityEvents'
import { identitySessionStorage, IdentityStorageKeys } from '../identitySessionStorage'
import { RETRY_OPTIONS } from '../utils/retry'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'

export interface OAuthTokens {
  access_token: string
  id_token: string
  token_type: string
  refresh_token?: string
  expires_in?: number
  scope?: string
}

type ConstructOIDCAuthorizationUrlParams = {
  clientId: string
  scope: string
  redirectUri: string
  locale?: string
  // todo: discuss sessionId is optional in SDKConfig
  // but was mandatory in our function
  // what happens if it's not provided
  sessionId?: string
}

type VerifyLoginParams = {
  clientId: string
  urlWithLoginParams: URL
  isOnPageFlow: boolean
  redirectUri?: string
}

export class AuthorizationServer {
  private authorizationServerMetadata: oauth.AuthorizationServer
  private environmentConfig: EnvironmentConfig

  constructor(
    authorizationServerMetadata: oauth.AuthorizationServer,
    environmentConfig: EnvironmentConfig
  ) {
    this.authorizationServerMetadata = authorizationServerMetadata
    this.environmentConfig = environmentConfig
  }

  getIdpOrigin = () => {
    return new URL(this.environmentConfig.oidc.idpUrl).origin
  }

  getOnPageFlowRedirectUri = () => {
    const idpOrigin = this.getIdpOrigin()
    const klarnaRedirectPage = `${idpOrigin}/popup/callback`
    const merchantEncodedOrigin = window.btoa(window.location.origin)
    const merchantEncodedRedirectURI = `${klarnaRedirectPage}/${merchantEncodedOrigin}`

    return merchantEncodedRedirectURI
  }

  constructOIDCAuthorizationUrl = async ({
    clientId,
    redirectUri,
    scope,
    sessionId,
    // todo: discuss if still necessary
    locale,
  }: ConstructOIDCAuthorizationUrlParams): Promise<URL> => {
    if (!this.authorizationServerMetadata.authorization_endpoint) {
      throw new Error('Authorization Endpoint not present')
    }

    const codeVerifier = oauth.generateRandomCodeVerifier()
    const codeChallenge = await oauth.calculatePKCECodeChallenge(codeVerifier)

    const nonce = oauth.generateRandomNonce()
    const state = oauth.generateRandomState()

    identitySessionStorage.set(IdentityStorageKeys.CodeVerifier, codeVerifier)
    identitySessionStorage.set(IdentityStorageKeys.Nonce, nonce)
    identitySessionStorage.set(IdentityStorageKeys.State, state)

    const searchParams = new URLSearchParams({
      client_id: clientId,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      redirect_uri: redirectUri,
      state,
      nonce,
      response_type: 'code',
      scope,
      funnel_id: sessionId,
    })

    // todo: discuss how to handle this
    const prompt = new URLSearchParams(window.location.search).get('klarna-auth-prompt')
    if (prompt) searchParams.set('prompt', prompt)

    if (locale) searchParams.set('ui_locales', locale)

    const authorizationUrl = new URL(this.authorizationServerMetadata.authorization_endpoint)
    authorizationUrl.search = searchParams.toString()

    return authorizationUrl
  }

  verifyLogin = async ({
    clientId,
    urlWithLoginParams,
    isOnPageFlow,
    redirectUri = '',
  }: VerifyLoginParams): Promise<OAuthTokens> => {
    const state = identitySessionStorage.get(IdentityStorageKeys.State)
    if (!state) throw new Error('state missing')

    const codeVerifier = identitySessionStorage.get(IdentityStorageKeys.CodeVerifier)
    if (!codeVerifier) throw new Error('codeVerifier missing')

    const nonce = identitySessionStorage.get(IdentityStorageKeys.Nonce)
    if (!nonce) throw new Error('nonce missing')

    const identityEvents = IdentityEvents.getInstance()

    const client: oauth.Client = {
      client_id: clientId,
      token_endpoint_auth_method: 'none',
    }

    const params = oauth.validateAuthResponse(
      this.authorizationServerMetadata,
      client,
      urlWithLoginParams,
      state
    )

    if (oauth.isOAuth2Error(params)) {
      identityEvents.emit('error', new Error([params.error, params.error_description].join(' - ')))

      const error = new Error('Invalid Authorization Response')
      errorHandler(error, { errorTitle: 'oauth.validateAuthResponse failed!', result: params })
      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginFailure,
        options: {
          reason: params.error_description,
        },
      })
      throw error
    }

    let tokenExchangeResponse
    try {
      tokenExchangeResponse = await retry(
        async () => {
          return oauth.authorizationCodeGrantRequest(
            this.authorizationServerMetadata,
            client,
            params,
            isOnPageFlow ? this.getOnPageFlowRedirectUri() : redirectUri,
            codeVerifier
          )
        },
        {
          ...RETRY_OPTIONS,
          onRetry: (error, attempt) => {
            errorHandler(error, {
              errorTitle: 'oauth.authorizationCodeGrantRequest call failed!',
              attempt,
            })
          },
        }
      )
    } catch (error) {
      errorHandler(error, {
        errorTitle: 'Token exchange failed',
      })
      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginFailure,
      })
      throw new TokenExchangeError(`Token Exchange failed. Reason: ${error}`)
    }

    if (!tokenExchangeResponse.ok) {
      const errorBody = await tokenExchangeResponse.json()
      const errorTitle = 'Token exchange failed'
      const error = new TokenExchangeError(errorTitle)
      errorHandler(error, {
        errorTitle,
        result: errorBody,
      })
      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginFailure,
      })
      throw error
    }

    const result = await oauth.processAuthorizationCodeOpenIDResponse(
      this.authorizationServerMetadata,
      client,
      tokenExchangeResponse,
      nonce
    )

    if (oauth.isOAuth2Error(result)) {
      const error = new Error('Invalid AuthorizationCodeOpenID Response')
      errorHandler(error, {
        errorTitle: 'oauth.processAuthorizationCodeOpenIDResponse failed!',
        result,
      })
      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginFailure,
      })
      throw error
    }

    const { access_token, id_token, token_type, refresh_token, scope, expires_in } = result

    return {
      access_token,
      id_token,
      token_type,
      refresh_token,
      scope,
      expires_in,
    }
  }
}
