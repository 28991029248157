import { backendBridge } from '@klarna-web-sdk/backend-bridge'
import { getUserLocale } from '@klarna-web-sdk/utils/src/getUserLocale'
import { getRegion } from '@klarna-web-sdk/utils/src/region'

import { EffectiveUxModes, TrackingEvents } from '../constants'
import {
  PaymentRequest as PaymentRequestType,
  PaymentRequestBodyAPI,
  PaymentRequestStateContext as PaymentRequestStateContextType,
} from '../types'
import { camelCaseToSnakeCaseMap, snakeCaseToCamelCaseMap } from './caseConverter'
import { tracker } from './paymentTracker'
import { store } from './store'

export async function updatePaymentRequest(
  paymentRequestId: string,
  paymentRequest?: PaymentRequestType,
  effectiveUxMode?: EffectiveUxModes
) {
  tracker().event(TrackingEvents.UPDATE_PAYMENT_REQUEST_CALLED, {
    paymentRequestId,
    effectiveUxMode,
  })

  const apiDataMap: Partial<PaymentRequestBodyAPI> = {}
  if (paymentRequest?.config?.redirectUrl)
    apiDataMap.config = { redirect_url: paymentRequest?.config?.redirectUrl }
  if (effectiveUxMode) apiDataMap.internal = { effective_ux_mode: effectiveUxMode }

  if (paymentRequest?.currency) apiDataMap.currency = paymentRequest.currency
  if (paymentRequest?.paymentAmount) apiDataMap.payment_amount = paymentRequest.paymentAmount
  if (paymentRequest?.buyer) apiDataMap.buyer = camelCaseToSnakeCaseMap(paymentRequest.buyer)
  if (paymentRequest?.merchantReference)
    apiDataMap.merchant_reference = paymentRequest.merchantReference
  if (paymentRequest?.lineItems)
    apiDataMap.line_items = camelCaseToSnakeCaseMap(paymentRequest.lineItems)
  if (paymentRequest?.shipping)
    apiDataMap.shipping = camelCaseToSnakeCaseMap(paymentRequest.shipping)
  const currency = paymentRequest?.currency || store.get('paymentRequest').currency

  const apiResponse = await backendBridge.call({
    method: 'paymentApiPatchAuthorizationRequest',
    data: {
      id: paymentRequestId,
      body: apiDataMap,
      region: getRegion({ paymentRequestId, currency, locale: getUserLocale() }),
    },
  })

  const response = snakeCaseToCamelCaseMap(apiResponse)

  store.set('paymentRequest', response.paymentRequest)
  store.set('paymentRequestId', response.paymentRequestId)
  store.set('paymentRequestState', response.state)
  store.set('paymentRequestStateContext', response.stateContext as PaymentRequestStateContextType)

  tracker().event(TrackingEvents.UPDATE_PAYMENT_REQUEST_COMPLETED, {
    paymentRequestId: response.paymentRequestId,
  })

  return response
}
