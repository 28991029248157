import {
  Popup,
  triggerIframe,
  triggerOnPage,
  WindowBlockedError,
} from '@klarna/flow-interaction-mode'
import { ETranslationKey, getTranslation } from '@klarna-web-sdk/utils/src/i18n'

import { EffectiveUxModes } from '../constants'

const interactionModeOptions = {
  overlayContent: {
    text: getTranslation(ETranslationKey.OverlayContentText),
    buttonLabel: getTranslation(ETranslationKey.OverlayContentButtonLabel),
  },
}

/**
 * Ideally tries to open a popup window, if blocked, creates an overlay backdrop and tries again.
 * If still blocked, opens a fullscreen iframe.
 *
 * @returns Promise with updateUrl and effectiveMode that were possible to open
 */
export function openOnPageInteraction({ onCloseIframe }: { onCloseIframe?: () => void }): Promise<{
  updateUrl: (url: string) => void
  effectiveMode: EffectiveUxModes
}> {
  return new Promise((resolve, reject) => {
    try {
      const { updateUrl } = triggerOnPage('about:blank', interactionModeOptions)
      const effectiveMode: EffectiveUxModes = EffectiveUxModes.WINDOW

      resolve({ updateUrl, effectiveMode })
    } catch (error) {
      if (error instanceof WindowBlockedError) {
        new Popup().openOverlay(() => {
          try {
            const { updateUrl } = triggerOnPage('about:blank', interactionModeOptions)
            resolve({ updateUrl, effectiveMode: EffectiveUxModes.WINDOW })
          } catch (error) {
            if (error instanceof WindowBlockedError) {
              const { updateUrl } = triggerIframe('about:blank', {
                ...interactionModeOptions,
                onClose: onCloseIframe,
              })

              resolve({
                updateUrl,
                effectiveMode: EffectiveUxModes.IFRAME,
              })
            } else {
              reject(error)
            }
          }
        }, interactionModeOptions)
      } else {
        reject(error)
      }
    }
  })
}
