import { InteractionMode } from '@klarna/flow-interaction-mode/dist/constants'
import {
  ElementArgs,
  ETranslationKey,
  getTranslation,
  klarnaElements,
  klarnaShapes,
  klarnaThemes,
} from '@klarna-web-sdk/utils'
import { EButtonLabel } from '@klarna-web-sdk/utils/src/getButtonLabel'
import { html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import kLogo from '../../assets/kLogo'
import { IdentityButton } from '../components/IdentityButton'
import { IdentitySDK } from '../components/IdentitySDK'
import styles from './style.scss'

export type ButtonLogoAlignment = 'left' | 'center' | 'right'

export interface ButtonOptions {
  buttonTheme: klarnaThemes
  buttonShape: klarnaShapes
  buttonLogoAlignment: ButtonLogoAlignment
  interactionMode: InteractionMode
}

type IdentityInteractionMode = Extract<InteractionMode, 'DEVICE_BEST' | 'ON_PAGE' | 'REDIRECT'>

export interface IdentityButtonConfiguration extends ElementArgs {
  shape?: klarnaShapes
  theme?: klarnaThemes
  logoAlignment?: ButtonLogoAlignment
  hideOverlay?: boolean
  // todo: fix scope inconsistencies
  scope: string
  redirectUri?: string
  interactionMode?: IdentityInteractionMode
}

const DEFAULT_ATTRIBUTES = {
  OVERLAY: true,
  SHAPE: klarnaShapes.DEFAULT,
  THEME: klarnaThemes.DEFAULT,
  LOGO: 'left',
  LABEL: EButtonLabel.CONTINUE,
} as const

const element = klarnaElements.IDENTITY_BUTTON

export function defineIdentityButton(identitySDK: IdentitySDK) {
  if (customElements.get(klarnaElements.IDENTITY_BUTTON)) {
    return
  }

  @customElement(element)
  class KlarnaIdentityButton extends LitElement {
    private identityButton: IdentityButton

    @property({ attribute: 'data-shape' })
    accessor shape: klarnaShapes = DEFAULT_ATTRIBUTES.SHAPE

    @property({ attribute: 'data-theme' })
    accessor theme: klarnaThemes = DEFAULT_ATTRIBUTES.THEME

    @property({ attribute: 'data-logo-alignment' })
    accessor logoAlignment: ButtonLogoAlignment = DEFAULT_ATTRIBUTES.LOGO

    @property({ attribute: 'data-hide-overlay' })
    accessor hideOverlay = 'false'

    // todo: discuss default values
    @property({ attribute: 'data-interaction-mode' })
    accessor interactionMode = ''

    @property({ attribute: 'data-scope' })
    accessor scope = ''

    @property({ attribute: 'data-redirect-uri' })
    accessor redirectUri = ''

    private alignLogo(logoAlignment: ButtonLogoAlignment) {
      const xWithKlarna = getTranslation(ETranslationKey.ContinueWith, { params: ['Klarna'] })

      switch (logoAlignment) {
        case 'center':
          return html`<span id="logo">${kLogo}</span> <span id="copy--center">${xWithKlarna}</span>`
        case 'right':
          return html`<span id="copy--right">${xWithKlarna}</span> <span id="logo">${kLogo}</span>`
        default:
          return html`<span id="logo">${kLogo}</span> <span id="copy">${xWithKlarna}</span>`
      }
    }

    private handleClick() {
      this.identityButton.click()
    }

    connectedCallback() {
      super.connectedCallback()
      this.identityButton = identitySDK.registerButton(this)
    }

    disconnectedCallback() {
      super.disconnectedCallback()
      identitySDK.unregisterButton(this)
    }

    render() {
      return html`
        <style>
          ${styles}
        </style>
        <button
          id="${element}"
          class="theme-${this.theme} shape-${this.shape}"
          @click=${this.handleClick}
        >
          <div id="${element}__outline"></div>
          <div id="${element}__inner-container">
            <span id="${element}__text"> ${this.alignLogo(this.logoAlignment)} </span>
          </div>
        </button>
      `
    }
  }

  return KlarnaIdentityButton
}
