import { EventBus, klarnaElements, logError } from '@klarna-web-sdk/utils'

import { IdentityButtonConfiguration } from '../klarnaIdentityButton/klarnaIdentityButton'
import { AuthFlow } from './AuthFlow'
import { AuthorizationServerRegistry } from './AuthorizationServerRegistry'
import { IdentitySDKConfig } from './IdentitySDK'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'

// todo: improve type definitions by coupling eventname with its data
type ButtonEvents = 'ready' | 'clicked'
type ButtonData = undefined | Error

// todo:discuss why EventBus is an abstract class?
class IdentityButtonEvents extends EventBus<ButtonEvents, ButtonData> {
  public constructor() {
    super()
  }
}

type IdentityButtonState = 'created' | 'mounted' | 'attached'

const DEFAULT_SCOPES = ['openid', 'offline_access']
const addDefaultScopesToConfig = (config: IdentityButtonConfiguration) => {
  const scope = [...new Set([...DEFAULT_SCOPES, ...config.scope.trim().split(' ')])].join(' ')

  return {
    ...config,
    scope,
  }
}

export class IdentityButton {
  public config: IdentityButtonConfiguration
  public buttonEvents: IdentityButtonEvents
  private sdkConfig: IdentitySDKConfig
  private element: Element
  private state: IdentityButtonState

  constructor(config: IdentityButtonConfiguration, sdkConfig: IdentitySDKConfig) {
    // todo: verify config
    this.config = addDefaultScopesToConfig(config)
    this.sdkConfig = sdkConfig
    this.buttonEvents = new IdentityButtonEvents()
    this.state = 'created'
  }

  mount(containerIdOrElement: string | Element) {
    if (this.state === 'mounted' || this.state === 'attached') {
      // todo: merchantLogger.warn with a message "already attached or mounted"
      return
    }

    const container =
      typeof containerIdOrElement === 'string'
        ? document.querySelector(containerIdOrElement)
        : containerIdOrElement

    if (!container) {
      logError('Missing valid `container`')
      throw new Error('Missing Container')
    }

    const htmlElement = document.createElement(klarnaElements.IDENTITY_BUTTON)
    const emptySpan = document.createElement('span')
    htmlElement.appendChild(emptySpan)

    const { id, ...args } = this.config
    Object.assign(htmlElement.dataset, args)
    htmlElement.id = id
    htmlElement.setAttribute('data-testid', id)

    container.appendChild(htmlElement)
    this.state = 'mounted'

    this.element = htmlElement
  }

  unmount() {
    if (!this.element) {
      // todo: merchantLogger.warn: already unmounted
      return
    }

    if (this.state !== 'mounted') {
      // todo: merchantLogger.warn: not mounted
      return
    }
    this.element.remove()
  }

  attach(buttonIdOrElement: string | Element) {
    if (this.state === 'mounted' || this.state === 'attached') {
      // todo: merchantLogger.warn with a message "already attached or mounted"
      return
    }

    const customButton =
      typeof buttonIdOrElement === 'string'
        ? document.querySelector(buttonIdOrElement)
        : buttonIdOrElement

    if (!customButton) {
      logError('Missing valid `button` element')
    }

    customButton.addEventListener('click', this.clickHandler)

    this.state = 'attached'

    this.element = customButton
  }

  detach() {
    if (!this.element) {
      // todo: merchantLogger.warn: already unmounted
      return
    }

    if (this.state !== 'attached') {
      // todo: merchantLogger.warn: not mounted
      return
    }
    this.element.removeEventListener('click', this.clickHandler)
  }

  on(eventName: ButtonEvents, callback: (data: ButtonData) => void) {
    this.buttonEvents.on(eventName, callback)
  }

  // todo: discuss should we have this public method?
  // It would block the popup but merchants can trigger redirect flow with this.
  async click() {
    await this.clickHandler()
  }

  async initAuthorizationServer() {
    return AuthorizationServerRegistry.getInstance().then((authServer) => {
      if (!authServer) {
        throw new Error('Could not create Authorization Server')
      }

      this.buttonEvents.emit('ready')

      IdentityTracker.sendEvent({
        name: TrackingEvents.ButtonReady,
        options: {
          state: this.state,
        },
      })
    })
  }

  // todo: discuss if we need this
  getPublicAPI() {
    // todo
    return {
      mount: this.mount.bind(this) as typeof this.mount,
      unmount: this.unmount.bind(this) as typeof this.unmount,
      attach: this.attach.bind(this) as typeof this.attach,
      detach: this.detach.bind(this) as typeof this.detach,
      on: this.on.bind(this) as typeof this.on,
    }
  }

  private clickHandler = async () => {
    IdentityTracker.sendEvent({
      name: TrackingEvents.ButtonClicked,
    })
    this.buttonEvents.emit('clicked')
    const authFlow = new AuthFlow(this.config, this.sdkConfig)

    authFlow
      .start()
      .then(() => {
        // console.log('Login successful')
      })
      .catch(() => {
        //  console.log('Login failed', error)
      })
  }
}

export type IdentityButtonPublicAPI = ReturnType<typeof IdentityButton.prototype.getPublicAPI>
