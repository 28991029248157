import { logError } from '@klarna-web-sdk/utils/src/logger'
import { v4 as uuid } from 'uuid'

import {
  ALLOWED_MESSAGE_ORIGIN_HOSTNAME_PATTERN,
  INIT_HANDSHAKE,
  INIT_HANDSHAKE_COMPLETE,
} from './constants'
import { MessageResolutionSchema, MessageSchema } from './schema'
import {
  CallbackObject,
  MessageResolutionSchemaType,
  MessageSchemaType,
  MethodHandler,
} from './types'

function isProduction() {
  return !['development', 'test'].includes(process.env.NODE_ENV as string)
}

/**
 * Messenger class for initiating communication between two browser contexts
 * like a parent window and a popup, or an iframe and its parent window.
 *
 * Messenger uses the MessageChannel API to establish a secure communication.
 */
export class Messenger {
  private static isIframe(value: Window | HTMLIFrameElement): value is HTMLIFrameElement {
    try {
      return !!(value as HTMLIFrameElement).contentWindow
    } catch (error) {
      return false
    }
  }

  private callbacks: Map<string, CallbackObject>
  public handshakeComplete: boolean = false
  public source: Window
  public sourcePort: MessagePort
  public target: HTMLIFrameElement | Window
  public targetPort: MessagePort

  constructor({ source, target }: { source: Window; target: HTMLIFrameElement | Window }) {
    this.callbacks = new Map()
    this.source = source
    this.target = target

    const { port1, port2 } = new MessageChannel()
    this.sourcePort = port1
    this.targetPort = port2
    this.sourcePort.onmessage = this.onMessageFromTarget.bind(this)
  }

  private onMessageFromTarget(ev: MessageEvent) {
    if (ev.data === INIT_HANDSHAKE_COMPLETE) {
      this.handshakeComplete = true
      return
    }

    const parsingResult = MessageResolutionSchema.safeParse(ev.data)
    if (!parsingResult.success) {
      logError('Invalid data schema received from target')
      return
    }

    const callback = this.callbacks.get(parsingResult.data.messageId)
    if (!callback) {
      logError(`Callback not available for method: ${parsingResult.data.method}`)
      return
    }

    const { reject, resolve, method } = parsingResult.data
    if (reject !== undefined) callback.reject(reject)
    else if (resolve !== undefined) callback.resolve(resolve)
    else logError(`No resolution available for method: ${method}`)
  }

  private waitForHandshake() {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Handshake timeout'))
      }, 5000)

      const interval = setInterval(() => {
        if (this.handshakeComplete) {
          clearTimeout(timeout)
          clearInterval(interval)
          resolve(true)
        }
      }, 100)
    })
  }

  public async postMessageToTarget({ method, data }: { method: string; data?: unknown }) {
    const messageId = uuid()
    const message: MessageSchemaType = { messageId, method, data }
    this.sourcePort.postMessage(message)

    return new Promise((resolve, reject) => {
      this.callbacks.set(messageId, { resolve, reject })

      // TODO: add a timeout in future to help with detecting errors
    })
  }

  public async initiateHandshake() {
    if (Messenger.isIframe(this.target)) {
      this.target.contentWindow?.postMessage({ type: INIT_HANDSHAKE }, '*', [this.targetPort])
    } else {
      this.target.postMessage({ type: INIT_HANDSHAKE }, '*', [this.targetPort])
    }

    await this.waitForHandshake()
  }

  public destroy() {
    this.sourcePort.close()
    this.targetPort.close()
  }
}

/**
 * MessengerTarget class receives messages from a Messenger instance.
 * Once a connection is established, message can be passed both ways.
 *
 * It will execute appropriate handlers based on method and handlers
 * needs to be registered to create an appropriate resolution.
 */
export class MessengerTarget {
  private readonly handlers: Map<string, MethodHandler>
  private port: MessagePort
  private sourceOrigin: string
  private handshakeComplete: boolean

  constructor({
    validateOrigin = true,
    removeListenerAfterHandshake = true,
  }: {
    validateOrigin?: boolean
    removeListenerAfterHandshake?: boolean
  }) {
    this.handlers = new Map()
    this.handshakeComplete = false

    const onGlobalMessageEvent = (event: MessageEvent) => {
      if (event.data.type !== INIT_HANDSHAKE) return
      if (!event.ports[0]?.postMessage) return

      if (validateOrigin && isProduction()) {
        if (!ALLOWED_MESSAGE_ORIGIN_HOSTNAME_PATTERN.test(new URL(event.origin).hostname)) return
      }

      this.port = event.ports[0]
      this.port.postMessage(INIT_HANDSHAKE_COMPLETE)
      this.port.onmessage = this.onMessageFromSource.bind(this)
      this.sourceOrigin = event.origin
      this.handshakeComplete = true

      if (removeListenerAfterHandshake) window.removeEventListener('message', onGlobalMessageEvent)
    }

    window.addEventListener('message', onGlobalMessageEvent)
  }

  private waitForHandshake() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.handshakeComplete) {
          clearInterval(interval)
          resolve(true)
        }
      }, 100)
    })
  }

  private async sendMessageToSource(message: MessageResolutionSchemaType) {
    if (!this.handshakeComplete) await this.waitForHandshake()
    this.port.postMessage(message)
  }

  private async onMessageFromSource(ev: MessageEvent) {
    const { messageId, method, data } = MessageSchema.parse(ev.data)

    const resolution: MessageResolutionSchemaType = {
      messageId,
      method,
      origin: this.sourceOrigin,
      reject: undefined,
      resolve: undefined,
    }

    const handler = this.handlers.get(method)
    if (!handler) {
      resolution.reject = `Unhandled method: ${method}, add appropriate handler.`
      this.sendMessageToSource(resolution)
      return
    }

    try {
      resolution.resolve = await handler({
        data,
        config: { sourceOrigin: this.sourceOrigin },
      })
    } catch (error) {
      resolution.reject = error
    }

    this.sendMessageToSource(resolution)
  }

  public registerHandler(method: string, handler: MethodHandler) {
    this.handlers.set(method, handler)
  }
}
