import { SdkBridgeReceiver } from '@klarna-web-sdk/backend-bridge'
import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'
import type { TrackingConfig } from '@klarna-web-sdk/utils'
import { logWarn } from '@klarna-web-sdk/utils'
import { klarnaElements } from '@klarna-web-sdk/utils/src/constants'
import { setupSentry } from '@klarna-web-sdk/utils/src/sentry'
import type { SDKConfig } from '@klarna-web-sdk/utils/src/types'

import { PaymentButton } from './apis/button'
import { canMakePayment as canMakePaymentAPI } from './apis/canMakePayment'
import { request } from './apis/request'
import { definePaymentButton } from './components/paymentButton'
import { ErrorTypes, PaymentEvents as EPaymentEvents } from './constants'
import type {
  CanMakePaymentOptions,
  OnUpdateCallback as OnUpdateCallbackType,
  PaymentButtonConfiguration,
  PaymentRequest as PaymentRequestType,
  PaymentRequestOptions as PaymentRequestOptionsType,
} from './types'
import { fetchPaymentRequest } from './utils/fetchPaymentRequest'
import { PaymentEvents } from './utils/paymentEvents'
import { configurePaymentTracker, PaymentTracker } from './utils/paymentTracker'
import { EPaymentStorageKeys, paymentStorage } from './utils/storage'
import { store } from './utils/store'

/**
 * The Klarna Payment interface provides access to the payment request API.
 *
 * The payment interface can have one on-going payment request at the time. The SDK keeps track of the current on-going request.
 *
 * Accepting a payment using the Klarna Payment button
 *
 * ```javascript
 * const klarna = ...;
 *
 * // Handle lifecycle update events
 * klarna.Payment.on("update", async (paymentRequest) => {
 *   // The ongoing payment request is available in the update event handler
 *   const state = paymentRequest.state;
 *   if (state == "PENDING_CONFIRMATION") {
 *     await fetch("https://example.com/my-backend", {
 *       method: 'POST',
 *       body: JSON.stringify({
 *         klarnaConfirmationToken: paymentRequest.result.paymentConfirmationToken
 *       });
 *     });
 *   }
 * });
 *
 * // Create a payment request
 * klarna.Payment.request({
 *   currency: "USD",
 *   paymentAmount: 1000
 * });
 *
 * // Handle button click event
 * klarna.Payment.button().on("click", (paymentRequest, button) => {
 *   // the ongoing payment request is available in the click handler
 *   paymentRequest.initiate();
 * });
 * ```
 *
 * <br/>
 *
 * ```html
 *  <klarna-payment-button></klarna-payment-button>
 * ```
 *
 */
export class PaymentSDK {
  /**
   * @hidden
   */
  public static updateCallback?: OnUpdateCallbackType

  /**
   * @hidden
   */
  public config: SDKConfig
  private eventBus: PaymentEvents
  private tracker: ReturnType<typeof configurePaymentTracker>

  constructor(config: SDKConfig) {
    this.config = config
    this.eventBus = PaymentEvents.getInstance()
    this.tracker = PaymentTracker.getInstance(config as TrackingConfig)

    store.set('config', config)
    store.set('emit', this.eventBus.emit.bind(this.eventBus))
    definePaymentButton(this)

    const sdkBridgeReceiver = new SdkBridgeReceiver()
    sdkBridgeReceiver.registerAllHandlers()

    this.setupSentryWithTags()
    this.retrieveStoredPaymentRequestId()
  }

  private setupSentryWithTags() {
    const tags = {
      accountId: this.config.accountId,
      clientId: this.config.clientId,
      product: klarnaElements.PLACEMENT,
    }
    setupSentry({
      environment: this.config.environment,
      version: this.config.version,
      tags,
    })
  }

  private async retrieveStoredPaymentRequestId() {
    let storedPaymentRequestId: string | undefined

    try {
      storedPaymentRequestId = paymentStorage.get(EPaymentStorageKeys.PaymentRequestId)
    } catch (error) {
      // ignore: possibly due to missing permissions
    }

    if (!storedPaymentRequestId) return

    try {
      await fetchPaymentRequest(storedPaymentRequestId)
    } catch (error) {
      throw new PaymentError(
        ErrorTypes.TECHNICAL_ERROR,
        'Failed to retrieve stored payment request',
        error
      )
    }
  }

  public button(buttonConfig: PaymentButtonConfiguration) {
    return new PaymentButton(buttonConfig).getPublicAPI()
  }

  /**
   * The request() interface retrieves the on-going request or creates a new request.
   * This interface is typically not required when working with the payment buttons
   * as payment requests are implicitly created and available in event callbacks.
   *
   * If this is called on a cancelled request of Klarna Payment, a new request is created.
   * To cancel a request and restart, the following pattern can be used.
   *
   * ```typescript
   * await klarna.Payment.request().cancel();
   * klarna.Payment.request().initiate();
   * ```
   *
   * @param paymentRequest
   * @param options
   * @returns
   */
  public request(
    paymentRequest?: PaymentRequestType | string,
    options?: PaymentRequestOptionsType
  ) {
    return request(paymentRequest, options)
  }

  /**
   * Register an event handler to manage payment request state transitions. Consult {@link PaymentRequestState} for state transitions.
   *
   * The provided handler is invoked when there is an update to the payment request. Note that
   * the handler may get triggered even if there is no state transition.
   *
   * The current state can be accessed using `paymentRequest.state`.
   *
   * During a redirection flow, the update handler is triggered once your page has loaded.
   * Pending updates are triggered even if the event handler is registered after the page has
   * loaded, eliminating the need for polling.
   *
   *   ```typescript
   * klarna.Payment.on("update", (paymentRequest) => {
   *    console.log(`Payment request is in state ${paymentRequest.state}`);
   * });
   * ```
   *
   * @param method - `update` event, called on state transitions. `error` for error handling.
   * @param callback
   * @returns
   */
  public on(method: 'update' | 'error', callback: OnUpdateCallbackType) {
    switch (method) {
      case 'update':
        PaymentSDK.updateCallback = callback
        return this.eventBus.on(EPaymentEvents.UPDATE, callback)
      case 'error':
        logWarn('on("error") is deprecated. Please use try/catch block.')
        return () => {}
      default:
        throw new Error(`Unsupported event: ${method}`)
    }
  }

  /**
   * Check if the consumer can make a payment with a given payment amount in the given currency and country.
   * @param options
   * @return {ReturnType<typeof canMakePaymentAPI>} - Returns true if the consumer can make a payment, otherwise returns false.
   */
  public canMakePayment = async (
    options: CanMakePaymentOptions
  ): ReturnType<typeof canMakePaymentAPI> => {
    return canMakePaymentAPI(options)
  }

  /**
   * @hidden
   */
  getPublicAPI(): {
    button: typeof PaymentSDK.prototype.button
    request: typeof PaymentSDK.prototype.request
    on: typeof PaymentSDK.prototype.on
    off: (typeof PaymentSDK.prototype.eventBus)['off']
    canMakePayment: typeof PaymentSDK.prototype.canMakePayment
  } {
    return {
      button: this.button.bind(this),
      request: this.request.bind(this),
      on: this.on.bind(this),
      off: this.eventBus.off.bind(this.eventBus),
      canMakePayment: canMakePaymentAPI,
    }
  }
}

export type PaymentSDKPublicAPI = ReturnType<typeof PaymentSDK.prototype.getPublicAPI>
