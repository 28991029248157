export enum klarnaElements {
  PAYMENT_BUTTON = 'klarna-payment-button',
  IDENTITY_BUTTON = 'klarna-identity-button',
  PLACEMENT = 'klarna-placement',
}

export enum klarnaButtons {
  IDENTITY = 'identity',
  PAYMENT = 'payment',
}

export enum klarnaThemes {
  DEFAULT = 'default',
  LIGHT = 'light',
  DARK = 'dark',
  OUTLINED = 'outlined',
}

export enum klarnaShapes {
  DEFAULT = 'default',
  PILL = 'pill',
  RECT = 'rect',
}

export enum ButtonLabel {
  CHECKOUT = 'checkout',
  CONTINUE = 'continue',
  CONNECT = 'connect',
  DONATE = 'donate',
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  SUBSCRIBE = 'subscribe',
  PAY = 'pay',
}

export enum klarnaPlacements {
  CHECKOUT = 'checkout',
  CUSTOM_TYPE_0 = 'custom-type0',
  CUSTOM_TYPE_1 = 'custom-type1',
  CUSTOM_TYPE_0_INLINE = 'custom-type0-inline',
  CUSTOM_TYPE_2_INLINE = 'custom-type2-inline',
  CUSTOM_TYPE_3_INLINE = 'custom-type3-inline',
  CUSTOM_TYPE_1_335_AUTO = 'custom-type1-335-auto',
  CUSTOM_TYPE_3_335_AUTO = 'custom-type3-335-auto',
  TOP_STRIP_PROMOTION_AUTO_SIZE = 'top-strip-promotion-auto-size',
  TOP_STRIP_PROMOTION_STANDARD = 'top-strip-promotion-standard',
  TOP_STRIP_PROMOTION_BADGE = 'top-strip-promotion-badge',
  CREDIT_PROMOTION_SMALL = 'credit-promotion-small',
  CREDIT_PROMOTION_STANDARD = 'credit-promotion-standard',
  CREDIT_PROMOTION_INLINE = 'credit-promotion-inline',
  CREDIT_PROMOTION_AUTO_SIZE = 'credit-promotion-auto-size',
  CREDIT_PROMOTION_BADGE = 'credit-promotion-badge',
  SIDEBAR_PROMOTION_AUTO_SIZE = 'sidebar-promotion-auto-size',
  FOOTER_PROMOTION_AUTO_SIZE = 'footer-promotion-auto-size',
  INFO_PAGE = 'info-page',
  HOMEPAGE_PROMOTION_TALL = 'homepage-promotion-tall',
  HOMEPAGE_PROMOTION_WIDE = 'homepage-promotion-wide',
  HOMEPAGE_PROMOTION_BOX = 'homepage-promotion-box',
}

export const API_V2_URL = 'payments/v2'
export const API_BASE_URLS: Record<string, string> = {
  production: 'https://js.klarna.com',
  playground: 'https://js.playground.klarna.com',
  staging: 'https://x.nonprod.us1.js.klarna.net',
  development: 'https://x.nonprod.us1.js.klarna.net',
}

export enum klarnaClientIdStage {
  LIVE = 'live',
  TEST = 'test',
}
