import {
  detectDeviceBest,
  InteractionModes,
  OnPagePopupModeOptions,
  triggerOnPage,
  triggerRedirect,
} from '@klarna/flow-interaction-mode'
import { ETranslationKey, getTranslation } from '@klarna-web-sdk/utils'

import { IntegrationError } from '../errors/integration-error'
import { IdentityButtonConfiguration } from '../klarnaIdentityButton/klarnaIdentityButton'
import { AuthorizationServerRegistry } from './AuthorizationServerRegistry'
import { IdentitySDKConfig } from './IdentitySDK'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'
import { PopupManager } from './PopupManager'

const POPUP_DIMENSIONS = {
  WIDTH: 400,
  HEIGHT: 929, // This value is the calc of the width * 2.16
}

type AuthFlowConfig = Pick<
  IdentityButtonConfiguration,
  'interactionMode' | 'redirectUri' | 'scope' | 'hideOverlay'
>

export class AuthFlow {
  public config: AuthFlowConfig
  private sdkConfig: IdentitySDKConfig

  constructor(config: AuthFlowConfig, sdkConfig: IdentitySDKConfig) {
    // todo: verify config
    this.config = config
    this.sdkConfig = sdkConfig
  }

  async start() {
    const interactionMode = this.findBestInteractionMode()

    if (interactionMode === 'REDIRECT') {
      return this.startRedirectFlow()
    }

    return this.startOnPageFlow()
  }

  private findBestInteractionMode() {
    if (this.config.interactionMode && this.config.interactionMode !== 'DEVICE_BEST') {
      return this.config.interactionMode
    }
    const bestMode = detectDeviceBest()
    if (bestMode === InteractionModes.IFRAME) {
      return `${InteractionModes.REDIRECT}`
    }

    return `${InteractionModes.ON_PAGE}`
  }

  private async startRedirectFlow() {
    IdentityTracker.sendEvent({
      name: TrackingEvents.RedirectFlowStarted,
    })
    const { redirectUri } = this.config
    if (!redirectUri) {
      throw new IntegrationError('redirectUri is not provided!')
    }

    const authServer = await AuthorizationServerRegistry.getInstance()

    const authUrl = await authServer.constructOIDCAuthorizationUrl({
      clientId: this.sdkConfig.clientId,
      redirectUri,
      scope: this.config.scope,
      sessionId: this.sdkConfig.sessionId,
      // todo: discuss Web SDK currently doesn't have locale
      // locale: this.sdkConfig.locale,
    })

    triggerRedirect(authUrl.toString())
  }

  private async startOnPageFlow() {
    IdentityTracker.sendEvent({
      name: TrackingEvents.OnPageFlowStarted,
    })
    const authServer = await AuthorizationServerRegistry.getInstance()

    const authUrl = await authServer.constructOIDCAuthorizationUrl({
      clientId: this.sdkConfig.clientId,
      redirectUri: authServer.getOnPageFlowRedirectUri(),
      scope: this.config.scope,
      sessionId: this.sdkConfig.sessionId,
    })

    const options: OnPagePopupModeOptions = {
      overlayContent: {
        text: getTranslation(ETranslationKey.OverlayContentText),
        buttonLabel: getTranslation(ETranslationKey.OverlayContentButtonLabel),
      },
      styles: {
        width: POPUP_DIMENSIONS.WIDTH,
        height: POPUP_DIMENSIONS.HEIGHT,
      },
      hideOverlay: this.config.hideOverlay,
    }
    try {
      const { getOpenedWindow } = triggerOnPage(authUrl.toString(), options)
      const popup = getOpenedWindow()
      const popupManager = new PopupManager({
        popup,
        authServer,
        sdkConfig: this.sdkConfig,
      })

      popupManager.goto(authUrl)
    } catch (error) {
      await this.startRedirectFlow()
    }
  }
}
