import { isValidLocale } from '@klarna-web-sdk/utils/src/isValidLocale'
import { logWarn } from '@klarna-web-sdk/utils/src/logger'

import { DEPRECATED_PLACEMENT_KEYS, PLACEMENTS, VALID_THEMES } from '../../config'
import getPaymentAmount from '../getPaymentAmount/getPaymentAmount'
import hasValidAmount from '../validateAmount/validateAmount'

const placementKeys = Object.keys(PLACEMENTS)

export default function hasValidPlacementConfiguration(dataset: DOMStringMap): boolean {
  const { key = '', locale = '', theme } = dataset

  /* In case of deprecated keys we still want to render, that's why we don't return false */
  if (DEPRECATED_PLACEMENT_KEYS.includes(key)) {
    logWarn(
      `The placement type ${key} will soon be deprecated. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/#deprecated-placement-types`
    )
  }

  /* In case of invalid theme keys we still want to render, that's why we don't return false */
  if (theme && !VALID_THEMES.includes(theme?.toLowerCase())) {
    logWarn(
      `The provide theme(${theme}) is not a valid argument. Please use one of these valid values: ${VALID_THEMES}.`
    )
  }

  /* Check that the placement Key is passed and is valid */
  if (!placementKeys.includes(key) && !key.includes('custom')) {
    logWarn(
      `${key} is not a valid placement type. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/`
    )
    return false
  }

  /* Check the locale is passed and is valid */
  const isLocaleValid = isValidLocale(locale)

  if (!locale) {
    logWarn(`undefined data-locale in klarna-placement`)
  } else if (!isLocaleValid) {
    logWarn(`invalid data-locale (${locale}) in klarna-placement`)
  }

  /* Check the amount is passed and is valid */
  const isValidAmount = hasValidAmount(key, getPaymentAmount(dataset))
  if (!isValidAmount) {
    return false
  }
  return true
}
