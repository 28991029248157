import { InteractionModes } from '@klarna/flow-interaction-mode'
import { Environment, IdentityConfig, Region, SDKConfig } from '@klarna-web-sdk/utils/src/types'

import { errorHandler } from '../errors'
import { IdentityEvents } from '../identityEvents'
import { identitySessionStorage, IdentityStorageKeys } from '../identitySessionStorage'
import {
  defineIdentityButton,
  IdentityButtonConfiguration,
} from '../klarnaIdentityButton/klarnaIdentityButton'
import { getEnvironmentConfig } from '../utils/environment'
import { getSigninResponse } from '../utils/getSigninResponse'
import { checkRedirectFlowTokens, doesUrlContainCodeAndState } from '../utils/redirectFlow'
import { AuthorizationServerRegistry } from './AuthorizationServerRegistry'
import { IdentityButton, IdentityButtonPublicAPI } from './IdentityButton'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'

export interface IdentitySDKConfig {
  clientId: string
  environment: Environment
  sessionId?: string
  identityRuntimeConfig?: IdentityConfig
}

const DEFAULT_ENVIRONMENT: Environment = 'playground'
const DEFAULT_BUTTON_ID = 'klarna-identity-button'

type ButtonsMap = Map<string, IdentityButton>

const readButtonConfigsFromStore = () => {
  try {
    const buttonConfigs = identitySessionStorage.get(IdentityStorageKeys.ButtonConfigurations)
    return buttonConfigs || []
  } catch (e) {}
  return []
}

const saveButtonConfigsToStore = (buttons: ButtonsMap) => {
  const configs = Array.from(buttons.entries()).map(([, button]) => button.config)
  identitySessionStorage.set(IdentityStorageKeys.ButtonConfigurations, configs)
}

export class IdentitySDK {
  private buttons: ButtonsMap
  private config: IdentitySDKConfig
  private identityEvents: IdentityEvents

  constructor(
    config: Pick<SDKConfig, 'sessionId' | 'environment' | 'clientId'> & {
      identityRuntimeConfig?: IdentityConfig
    }
  ) {
    this.config = {
      environment: DEFAULT_ENVIRONMENT,
      ...config,
    }
    this.identityEvents = IdentityEvents.getInstance()

    identitySessionStorage.set(IdentityStorageKeys.SessionId, this.config.sessionId)
    this.buttons = new Map<string, IdentityButton>()

    const { tracker: trackerConfig } = getEnvironmentConfig(
      this.getRegion(),
      this.config.environment
    )
    IdentityTracker.configureInstance(this.config, trackerConfig)

    defineIdentityButton(this)
  }

  button(arg?: string | IdentityButtonConfiguration): IdentityButtonPublicAPI | never {
    AuthorizationServerRegistry.setEnvironment(this.config.environment)
    AuthorizationServerRegistry.setRegion(this.getRegion())
    if (!arg) {
      if (this.buttons.size < 1) {
        throw new Error('There are no buttons!')
      }

      if (this.buttons.size > 1) {
        throw new Error('There are multiple identity buttons. Please provide an id')
      }

      // get first button
      return this.buttons.values().next().value as IdentityButtonPublicAPI
    }

    if (typeof arg === 'string') {
      return this.buttons.get(arg)
    }

    const identityButton = this.createButton(arg)
    return identityButton.getPublicAPI()
  }

  public on: typeof this.identityEvents.on = (eventName, callback) => {
    AuthorizationServerRegistry.setEnvironment(this.config.environment)
    AuthorizationServerRegistry.setRegion(this.getRegion())
    this.identityEvents.on(eventName, callback)

    if (eventName === 'signin' && doesUrlContainCodeAndState()) {
      // todo: add tracking event
      this.checkRedirectFlowResponse()
        .then(() => {
          // console.log('Login successful')
        })
        .catch((err) => {
          errorHandler(err, { errorTitle: 'redirectFlowResponse check failed!' })
        })
    }
  }

  getIdentityPublicAPI = () => {
    return {
      button: this.button.bind(this),
      on: this.on.bind(this),
    }
  }

  registerButton(htmlElement: HTMLElement) {
    const id = htmlElement.getAttribute('id')

    if (id && this.buttons.get(id)) {
      return this.buttons.get(id)
    }

    const config = {
      id,
      ...(htmlElement.dataset as unknown as IdentityButtonConfiguration),
      hideOverlay: htmlElement.dataset['hideOverlay']?.toLocaleLowerCase() === 'true',
    }
    // todo: verify config & remove invalid config

    const identityButton = this.createButton(config, htmlElement)
    return identityButton
  }

  unregisterButton(htmlElement: HTMLElement) {
    const config = htmlElement.dataset as unknown as IdentityButtonConfiguration
    // todo: verify config & remove invalid config
    this.buttons.delete(config.id)
    saveButtonConfigsToStore(this.buttons)

    // todo: discuss cleanup sessionStorage as well?
  }

  private getRegion = (): Region => {
    if (!this.config.identityRuntimeConfig?.naClientIds) {
      const errorTitle = 'Identity naClientIds list not found'
      errorHandler(new Error(errorTitle), { errorTitle })
    }

    const naClientIds = this.config.identityRuntimeConfig?.naClientIds ?? []

    return naClientIds.includes(this.config.clientId) ? 'NA' : 'EU'
  }

  private checkRedirectFlowResponse = async () => {
    // load button configuration
    const buttons = readButtonConfigsFromStore()
    buttons.forEach(this.createButton)

    // iterate over buttons
    for (const [, identityButton] of this.buttons.entries()) {
      const tokens = await checkRedirectFlowTokens({
        redirectUri: identityButton.config.redirectUri,
        clientId: this.config.clientId,
      })

      if (tokens) {
        this.identityEvents.emit('signin', getSigninResponse(tokens))

        IdentityTracker.sendEvent({
          name: TrackingEvents.LoginSuccess,
          options: {
            flow: InteractionModes.REDIRECT,
          },
        })

        return
      }
    }
  }

  private createButton = (arg: IdentityButtonConfiguration, htmlElement?: HTMLElement) => {
    if (!arg.id) {
      if (this.buttons.size > 0) {
        throw new Error('There are identity buttons registered. Please provide an id')
      }
      arg.id = DEFAULT_BUTTON_ID
      if (htmlElement) {
        htmlElement.setAttribute('id', arg.id)
        htmlElement.setAttribute('data-testid', arg.id)
      }
    }

    const identityButton = new IdentityButton(arg, this.config)
    this.buttons.set(arg.id, identityButton)

    identityButton.initAuthorizationServer().catch((err) => {
      const errorTitle = 'Could not create Authorization Server'
      this.identityEvents.emit('error', new Error(errorTitle))
      errorHandler(err, {
        errorTitle,
      })
    })

    saveButtonConfigsToStore(this.buttons)

    return identityButton
  }
}

export type IdentitySDKPublicAPI = ReturnType<typeof IdentitySDK.prototype.getIdentityPublicAPI>
