import { klarnaPlacements } from '@klarna-web-sdk/utils/src/constants'

export const SUPPORTED_LOCALES = [
  'en-AU',
  'en-NZ',
  'en-AT',
  'de-AT',
  'nl-BE',
  'en-BE',
  'fr-BE',
  'en-CH',
  'de-CH',
  'it-CH',
  'fr-CH',
  'en-CZ',
  'cs-CZ',
  'de-DE',
  'en-DE',
  'da-DK',
  'en-DK',
  'es-ES',
  'en-ES',
  'fi-FI',
  'sv-FI',
  'en-FI',
  'fr-FR',
  'en-FR',
  'en-GB',
  'en-GR',
  'el-GR',
  'en-IE',
  'en-IT',
  'it-IT',
  'nl-NL',
  'en-NL',
  'no-NO',
  'nb-NO',
  'en-NO',
  'en-PL',
  'pl-PL',
  'en-PT',
  'pt-PT',
  'en-RO',
  'ro-RO',
  'sv-SE',
  'en-SE',
  'en-CA',
  'fr-CA',
  'es-MX',
  'en-MX',
  'en-US',
  'es-US',
  'en-HU',
  'hu-HU',
  'en-SK',
  'sk-SK',
].map((l: string) => l.toLowerCase())

export const DEPRECATED_PLACEMENT_KEYS = [
  'top-strip-promotion-standard',
  'credit-promotion-standard',
  'credit-promotion-small',
  'info-page-standard',
  'info-page-auto-size',
]

export const PLACEMENTS = {
  [klarnaPlacements.FOOTER_PROMOTION_AUTO_SIZE]: 'static',
  [klarnaPlacements.SIDEBAR_PROMOTION_AUTO_SIZE]: 'static',
  [klarnaPlacements.CHECKOUT]: 'json',
  [klarnaPlacements.CREDIT_PROMOTION_AUTO_SIZE]: 'json',
  [klarnaPlacements.CREDIT_PROMOTION_BADGE]: 'json',
  [klarnaPlacements.CREDIT_PROMOTION_STANDARD]: 'json',
  [klarnaPlacements.CREDIT_PROMOTION_SMALL]: 'json',
  [klarnaPlacements.TOP_STRIP_PROMOTION_BADGE]: 'json',
  [klarnaPlacements.HOMEPAGE_PROMOTION_TALL]: 'json',
  [klarnaPlacements.HOMEPAGE_PROMOTION_WIDE]: 'json',
  [klarnaPlacements.HOMEPAGE_PROMOTION_BOX]: 'json',
  [klarnaPlacements.TOP_STRIP_PROMOTION_AUTO_SIZE]: 'json',
  [klarnaPlacements.TOP_STRIP_PROMOTION_STANDARD]: 'json',
  [klarnaPlacements.INFO_PAGE]: 'json',
  /** Saks and Fifth Avenue, styling differently between PDP / checkout  */
  [klarnaPlacements.CUSTOM_TYPE_0]: 'json',
  [klarnaPlacements.CUSTOM_TYPE_1]: 'json',
  [klarnaPlacements.CUSTOM_TYPE_1_335_AUTO]: 'json',

  /* @TODO the following placements should be implemented through JSON from OSM API */
  [klarnaPlacements.CREDIT_PROMOTION_INLINE]: 'html',
  [klarnaPlacements.CUSTOM_TYPE_2_INLINE]: 'html', // N100829
  [klarnaPlacements.CUSTOM_TYPE_3_INLINE]: 'html', // N100131
  [klarnaPlacements.CUSTOM_TYPE_0_INLINE]: 'html',
  [klarnaPlacements.CUSTOM_TYPE_3_335_AUTO]: 'html', // N100990
}
export const VALID_THEMES = ['default', 'dark', 'custom', 'light']
export const ASSETS_URL = {
  production: {
    oc: 'https://osm.klarnaservices.com',
    eu: 'https://osm.klarnaservices.com',
    na: 'https://osm.klarnaservices.com',
  },
  playground: {
    oc: 'https://oc-assets.playground.klarnaservices.com',
    eu: 'https://eu-assets.playground.klarnaservices.com',
    na: 'https://na-assets.playground.klarnaservices.com',
  },
  staging: {
    oc: 'https://s3.int.klarna.net/pre-purchase/library/global',
    eu: 'https://s3.int.klarna.net/pre-purchase/library/global',
    na: 'https://s3.int.klarna.net/pre-purchase/library/global',
  },
  development: {
    oc: 'https://oc-assets.klarnaservices.com',
    eu: 'https://eu-assets.klarnaservices.com',
    na: 'https://na-assets.klarnaservices.com',
  },
}
export const MESSAGING_ENDPOINT = '/messaging'
export const HTML_ENDPOINT = '/s' // will be deprecated once all placements switch to JSON response
export const INLINE_ENDPOINT = '/i' // will be deprecated once all placements switch to JSON response
export const CHANNEL = 'web'
export const EVENT_SAMPLING_PERCENTAGE = {
  IMPRESSIONS: 50,
  AGR_IMPRESSIONS: 10,
  PERFORMANCE: 5,
  STYLING_ISSUES: 2,
  LEGACY_RENDERING: 1,
}

export const MERCHANTS_WITH_CUSTOM_ADS = [
  // EU
  '2353e544-2ff2-59c2-b212-1bf4f3464e4c',
  '8881c1ea-b112-5bac-af6e-8d7673373121',
  '69ae9909-fa83-5679-9b79-e3152d192025',
  '8a9301af-27eb-579f-9680-ce15f71b6b0a',
  '1e91f355-200d-5918-9a94-e89ab9989159',
  '55253bbc-5618-57c1-9537-dcb976a96121',
  '90182b69-f31b-5041-8277-82b3a07eae17',
  '5c1f8dc6-5298-5c13-abef-6a253ea06ea5',
  'da5a1c6d-c02c-514c-b40d-73b0d676d464',
  '67a23d03-8270-5475-a8a0-babe4ba86687',
  '4f39e2bc-5713-52f1-8c90-570f7ddd4781',
  '5c067b36-6a15-5c22-9fba-1402e1393748',
  'a9e8fb36-aa4e-589d-bd62-7c8c99e46092',
  '14573f95-97e7-52f1-b7f2-a903accb435b',
  'cce1bc9a-2ba6-59ab-88ea-ba0b767aab8b',
  'e8880bde-72dd-5de0-8876-4dc28a556ff9',
  'a9c619b7-b577-54d7-aa7b-adeadc7fc5ee',
  'e81bcf70-038a-546b-8bd6-0c08ea4733e2',
  'ce4123ca-9676-51b5-8db5-76d854d54c88',
  // US
  '723d3bf3-0a90-5aae-8140-310d9fa478bd',
  '78384c12-9830-508f-9046-f45b93ede7a1',
  '52de8130-3107-59f9-aaa1-5a90bf0cdf13',
  'ecb0e555-fd5f-554e-966a-bf58f1f72a8e',
  'c6406cbf-893d-59c1-816d-086e9ca7c5c7',
  '76424947-b4c8-5f11-9125-46bff698a0f8',
  '58a92553-dc92-5af4-af7e-65693266e81c',
  'a84c8537-64a5-5f76-a0ff-6d9b934088ec',
  '1fb43ba0-2264-5dd0-9ccd-7d6a889613bf',
  '601ee633-997e-5b4e-867a-a5a13c4c5f63',
  'c782324b-2ee6-5130-9606-c7f112a0fe23',
  '78bdf5e2-8292-54ee-9e27-dea8fb4aacf6',
  'fc432336-af8d-571e-8a4c-15d0efcf7f67',
  '663643dc-79c8-5ecd-ba91-86403fef3965',
  '67472e2d-1d9a-5311-87ef-9e93ea03f399',
  'fce97b2a-8c6a-5ba5-a107-948485bc2e83',
  '0b42169a-608f-5c81-b17a-29900c8c66a4',
  'ca810ec7-49ec-5b63-9183-accae4bf3307',
  'c68e9833-599c-5e14-bc57-f78c822b12ad',
  'bfd35799-3d4c-5582-b71b-c3e963568427',
  '1a13d8c3-3c33-51ee-a722-e45955c20fa6',
  'ad0754c5-71a6-54ac-96c0-f941a0bb7f5c',
  'd3e8cc9b-a1ad-51b9-96a7-05fe230edce3',
  '062884b5-df83-531b-828c-d3118de89b06',
  '49a82178-2eba-54ce-b8c1-2531a004a138',
  '71d3225b-aa87-5f0e-9904-876af56d6c66',
  'e4708f00-bd5a-5e18-bd66-f4621c238887',
  'aad339e0-64b3-54d8-8982-a6df9322269e',
  'f6186bbc-da96-57ed-affd-c0978a613268',
  '2bc7b9f0-0433-5989-9ee0-a4b375bf929c',
  '991fe595-2850-5cbc-adba-989fc270713f',
  '1afa0d69-128e-56cf-8879-328f60a66c65',
  '3a543f47-e295-5ea0-93ab-61dfb0dae37a',
  'addb8461-59ed-5e10-b577-9abcbd197d84',
  '6ca24f4c-0c64-5517-adb2-fd5eda9701aa',
  'cbca9c58-53b0-522a-a432-a6103901733c',
  '19e055a1-5941-513a-8d5f-676b6d10b013',
  '738bf438-6a08-50db-92e1-acfdc06706c2',
  '9ce697be-c972-53b9-88bb-cac352b69f47',
  'a897c548-029d-5d34-9b59-813ceb9d7264',
  '998bc69d-b942-5eff-9edf-b1b1e81ae391',
  'c7a1c0da-0b49-5732-913b-0a47a3e1a60e',
  '9ee94060-75f8-5f0e-99bb-3954fcf3a62f',
  'db8aa4d5-8069-5218-933c-de7e3b494f89',
  'a264dbba-6c2d-59b9-9167-c3f4a9791f21',
  '008032f1-657e-5cca-a105-f3edd2bbe84f',
  'af16f814-4a20-5c20-ad2d-942ad93f3590',
  'e36cb7cd-c699-555c-a80d-0fcd56d7f303',
  'ba76a301-1323-5e62-aac3-6b3ce95623ee',
  '0904ad5a-0fb5-5069-bca9-12dbae8d8e5d',
  '6b01ee08-344c-5e9b-ae46-b31a477259f0',
  'f53b110c-50fa-5540-8225-bad651221124',
  '23aec4ae-cd98-5f0a-b4d5-0df5c4a223c6',
  '17e2e730-3d33-585a-b9e7-c5a75e966127',
  '203b5252-c6b6-56a1-8e9d-d0caafebb88c',
  '63ae67c6-ab0e-575e-9f1c-712b9027cc6c',
  'a17591c7-36f9-526b-92a5-f2ff0449a3fc',
  '9766ad09-9605-58a9-aed8-0cd3011da25e',
  'fd6d2432-21c6-5928-86ad-eb0566325c12',
  '48de90a6-1bce-576b-a8eb-6d649c96b55c',
  '0403e8ae-1038-580c-be1c-c38d38837f01',
  '32198a5b-390c-59f6-b1b8-b318f945a14f',
  'd8a32f56-c17e-5e88-bc50-b9b35b9ced2d',
  '6600934b-c14a-54d5-a447-6dc4b146f321',
  '7cfbaac4-729a-5930-ba1a-ad3504155e8e',
  '614d55ad-6cb6-5670-9879-49fd2cac64bf',
  'f46705b8-e9e5-5070-80c6-389dcc32f0d4',
  '6d98ac83-11a1-584b-9aa4-e4374ea442e6',
  '19cf327c-7c49-5b82-853b-83d93c0fa116',
]
