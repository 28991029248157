import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'

import { EffectiveUxModes, ErrorTypes, TrackingEvents } from '../constants'
import { PaymentRequest, PaymentRequestOptions } from '../schema'
import {
  PaymentRequest as PaymentRequestType,
  PaymentRequestOptions as PaymentRequestOptionsType,
} from '../types'
import { emitUpdate } from '../utils/emitUpdate'
import { makePaymentRequest } from '../utils/makePaymentRequest'
import { tracker } from '../utils/paymentTracker'
import { store, storeUpdatePaymentRequest, storeUpdatePaymentRequestOptions } from '../utils/store'
import { request } from './request'

export async function submit(
  paymentRequest?: Partial<PaymentRequestType>,
  options?: PaymentRequestOptionsType
) {
  const paymentRequestId = store.get('paymentRequestId')
  tracker().event(TrackingEvents.SUBMIT_CALLED, { ...options, paymentRequestId })

  if (paymentRequest) storeUpdatePaymentRequest(paymentRequest)
  if (options) storeUpdatePaymentRequestOptions(options)

  const _paymentRequest = store.get('paymentRequest')
  const _options = store.get('paymentRequestOptions')

  if (paymentRequestId) {
    throw new PaymentError(
      ErrorTypes.RESOURCE_ERROR,
      'There is an ongoing payment request, please use the `Klarna.Payment.request().update()` method instead.'
    )
  }

  try {
    PaymentRequest.parse(_paymentRequest)
  } catch (error) {
    throw new PaymentError(ErrorTypes.INPUT_ERROR, 'Invalid PaymentRequest', error)
  }

  try {
    PaymentRequestOptions.parse(_options)
  } catch (error) {
    throw new PaymentError(ErrorTypes.INPUT_ERROR, 'Invalid PaymentRequestOptions', error)
  }

  try {
    const response = await makePaymentRequest(
      _paymentRequest as PaymentRequestType,
      EffectiveUxModes.REDIRECT
    )

    emitUpdate()
    tracker().event(TrackingEvents.SUBMIT_COMPLETED, {
      paymentRequestId: response.paymentRequestId,
    })
    return request()
  } catch (error) {
    throw new PaymentError(ErrorTypes.TECHNICAL_ERROR, 'Submission failed', error)
  }
}
